import { useState, useRef, useEffect, Fragment } from "react"
import { YMInitializer } from "react-yandex-metrika"
import InputMask from "react-input-mask"
import { DateTime } from "luxon"
import "./App.css"
import Logo3x from "./assets/logo.png"
import PrivacyPolicy from "./assets/privacy_policy.pdf"
import AppleWalletLogo from "./assets/Add_to_Apple_Wallet_rgb_RU.svg"
import GoogleWalletLogo from "./assets/ru_add_to_google_wallet_add-wallet-badge.svg"

const timerLength = 90 // seconds
const uri = "https://customer.api.cw.marketing"
const COMPANY_ACCESS_KEY = 'lA8YqeCP6nxog80g15nKGlSz4VmZeWoThppQGOvvfrwEnVJm7X64lqnqjvlwcVz0'
const LOYALATY_ID = '6322b63845101d3d889aa6f3'
class ResponseError extends Error {
    constructor(message, status, errMessage) {
        super(message)
        this.name = "ResponseError"
        this.status = status
        this.errMessage = errMessage
    }
}

const App = () => {
    const timerRef = useRef(null)
    const codeRef = useRef(null)
    const notificationRef = useRef(null)
    const submitRef = useRef(null)
    const afterSignup = useRef(null)
    const codeBtnRef = useRef(null)
    const smsBlockRef = useRef(null)
    const beforeUserCheckRef = useRef(null)
    const afterUserCheckRef = useRef(null)
    const afterUserCheckRadiosRef = useRef(null)
    const smsInputBlockRef = useRef(null)

    const [time, setTime] = useState(timerLength)
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [dob, setDob] = useState("")
    const [phone, setPhone] = useState("")
    const [sex, setSex] = useState("female")
    const [code, setCode] = useState("")
    const [disclaimer, setDisclaimer] = useState(true)
    const [signupIsEnabled, setSignupIsEnabled] = useState(false)
    const [token, setToken] = useState("")
    const [appleWallet, setAppleWallet] = useState("")
    const [googleWallet, setGoogleWallet] = useState("")

    const [card, setCard] = useState("")

    const parsePhone = (phone) => phone.replace(/^\+7|^7|^8|[\s_()-]/g, '')
    const parseCode = (code) => code.replace(/_/g, '')

    useEffect(() => {
        const p = parsePhone(phone)
        if (codeBtnRef) {
            codeBtnRef.current.disabled = p.length !== 10
        }
    }, [ phone ])

    useEffect(() => {
        const c = parseCode(code)
        if (codeRef) {
            if (c.length === 4) {
                const p = parsePhone(phone)
                codeRef.current.disabled = true

                fetch(`${ uri }/api/v1/auth/token`, {
                    method: "POST",
                    body: JSON.stringify({ phone: parseInt(p, 10), code: c }),
                    headers: {
                        'Company-Access-Key': COMPANY_ACCESS_KEY,
                        'Loyalaty-Id': LOYALATY_ID,
                        'Content-Type': 'application/json'
                    }
                })
                    .then((res) => {
                        if (!res.ok)
                            throw new ResponseError(res.statusText, res.status)
                        return res.json()
                    })
                    .then(({ isRegistered, access_token }) => {
                        if (isRegistered) {
                            fetch(`${ uri }/api/v1/me/profile`, {
                                headers: {
                                    'Company-Access-Key': COMPANY_ACCESS_KEY,
                                    'Loyalaty-Id': LOYALATY_ID,
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${access_token}`
                                }
                            }).then((res) => {
                                if (!res.ok)
                                    throw new ResponseError(res.statusText, res.status)
                                return res.json()
                            }).then(({card, googleWallet, wallet}) => {
                                if (card)
                                    setCard(card)
                                if (wallet.card && wallet.card.length > 0)
                                    setAppleWallet(wallet.card)
                                if (googleWallet && googleWallet.length > 0)
                                    setGoogleWallet(googleWallet)
                                afterSignup.current.classList.add("form__after-signup--block")
                            })
                           
                        }

                        if (!isRegistered && access_token) {
                            if (smsBlockRef.current) {
                                smsBlockRef.current.classList.add("form__inputs--hidden")
                            }
                            if (afterUserCheckRef.current) {
                                afterUserCheckRef.current.classList.remove("form__inputs--hidden")
                            }
                            if (afterUserCheckRadiosRef.current) {
                                afterUserCheckRadiosRef.current.classList.remove("form__inputs--hidden")
                            }
                            setSignupIsEnabled(true)
                            setToken(access_token)
                        }

                        codeRef.current.classList.add("success")
                    })
                    .catch((err) => {
                        if (err instanceof ResponseError) {
                            console.log("err")
                            if (err.status === 401) {
                                codeRef.current.classList.add("error")
                                codeRef.current.disabled = false
                            }
                        }
                    })
            }
        }
    }, [ code ])

    const onSendCodeClick = (e) => {
        e.preventDefault()

        const p = parsePhone(phone)
        if (p.length !== 10) return

        codeRef.current.disabled = false

        const btn = e.target
        btn.disabled = true
        btn.innerHTML = "Выслать код повторно"
        if (timerRef) {
            timerRef.current.classList.remove("form__timer--hidden")
            let a = time
            const t = setInterval(() => {
                if (a > 0) {
                    a -= 1
                } else {
                    clearInterval(t)
                    btn.disabled = false
                    a = timerLength
                    timerRef.current.classList.add("form__timer--hidden")
                }
                setTime(a)
            }, 1000)
        }

        if (smsInputBlockRef.current) {
            smsInputBlockRef.current.classList.remove("form__inputs--hidden")
        }


        ////
        fetch(`${ uri }/api/v1/auth/code`, {
            method: "POST",
            body: JSON.stringify({ phone: parseInt(p, 10) }),
            headers: {
                'Company-Access-Key': COMPANY_ACCESS_KEY,
                'Loyalaty-Id': LOYALATY_ID,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {
                if (res.status !== 200)
                    throw new Error("Can't send a request")
                return res.status
            })
            .then(() => {
                if (smsInputBlockRef.current) {
                    smsInputBlockRef.current.classList.remove("form__inputs--hidden")
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const onSubmit = (e) => {
        e.preventDefault()
        submitRef.current.disabled = true

        notificationRef.current.innerHTML = ""
        const errClass = notificationRef.current.classList.contains("form__notification--error")
        const dateDob = DateTime.fromFormat(dob, "dd.LL.y")

        if (dateDob >= DateTime.now()) {
            notificationRef.current.innerHTML = "Необходимо указать правильную дату рождения"
            if (!errClass)
                notificationRef.current.classList.add("form__notification--error")

            submitRef.current.disabled = false
            return
        }

        if (notificationRef) {
            if (firstName.length < 2) {
                notificationRef.current.innerHTML = "Необходимо указать имя"
                if (!errClass)
                    notificationRef.current.classList.add("form__notification--error")

                submitRef.current.disabled = false
                return
            }

            if (lastName.length < 2) {
                notificationRef.current.innerHTML = "Необходимо указать фамилию"
                if (!errClass)
                    notificationRef.current.classList.add("form__notification--error")

                submitRef.current.disabled = false
                return
            }

            // if (email.length < 2) {
            //     notificationRef.current.innerHTML = "Необходимо указать город"
            //     if (!errClass)
            //         notificationRef.current.classList.add("form__notification--error")
            //     return
            // }

            if (!dateDob.isValid) {
                notificationRef.current.innerHTML = "Необходимо указать дату рождения"
                if (!errClass)
                    notificationRef.current.classList.add("form__notification--error")

                submitRef.current.disabled = false
                return
            }

            if (dateDob.year <= 1900) {
                notificationRef.current.innerHTML = "Необходимо указать год рождения больше 1900"
                if (!errClass)
                    notificationRef.current.classList.add("form__notification--error")

                submitRef.current.disabled = false
                return
            }
        }

        submitRef.current.classList.add("form__submit--loading")

        fetch(`${ uri }/api/v1/auth/signup`, {
            method: "POST",
            headers: {
                'Company-Access-Key': COMPANY_ACCESS_KEY,
                'Loyalaty-Id': LOYALATY_ID,
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${ token }`
            },
            body: JSON.stringify({
                firstName, lastName, email: email !== "" ? email : null,
                sex, dob: dateDob.toFormat("y-LL-dd")
            })
        }).then((res) => {
            if (!res.ok)
                throw new ResponseError(res.statusText, res.status)
            return res.json()
        }).then(({appleWallet: aw, googleWallet: gw}) => {
            if (aw)
                setAppleWallet(aw)
            if (gw)
                setGoogleWallet(gw)
            afterSignup.current.classList.add("form__after-signup--block")
        }).catch((err) => {
            if (err instanceof ResponseError) {
                if (err.status === 500) {
                    notificationRef.current.innerHTML = "Произошла ошибка на сервере. Пожадуйста, попробуйте зарегистрироваться позже"
                    if (!errClass)
                        notificationRef.current.classList.add("form__notification--error")
                } else if (err.status === 400) {
                    notificationRef.current.innerHTML = "Данный номер уже зарегистрирован в нашей программе лояльности. Отправить карту по SMS?"
                    if (!errClass)
                        notificationRef.current.classList.add("form__notification--error")
                }
            }
            submitRef.current.disabled = false
            submitRef.current.classList.remove("form__submit--loading")
        })

    }

    const parseTime = (time) => {
        let minute = Math.floor(time / 60)
        let seconds = time % 60

        if (minute < 10)
            minute = `0${ minute }`

        if (seconds < 10)
            seconds = `0${ seconds }`

        return `${ minute }:${ seconds }`
    }

    return (
        <main className="main">

            <YMInitializer
                accounts={[ 89619274 ]}
                options={{
                    clickmap: true,
                    trackLinks: true,
                    accurateTrackBounce: true,
                    webvisor: true,
                    trackHash: true
                }}
                version="2"
            />

            <img src={ Logo3x } alt="Vita Margarita" className="main__logo" />
            <div id="test" />
            <div className="main__description">
                <h3>Добро пожаловать в программу лояльности VitaMargarita!</h3>
                <p>
                    Vita Bonus - это возможность узнавать первым о новых блюдах и изменениях в нашем меню.<br/>
                    Но в первую очередь, позволит копить бонусные рубли, которыми можно оплатить любимые блюда до 100% от всей стоимости покупки.<br/>
                    А также получать эксклюзивные предложения, которые доступны только участникам бонусной программы.<br/>
                </p>
                <p>
                    5% - приветственный бонус при установке<br/>
                    7% - получаете при накоплении 1500 бонусных рублей<br/>
                    10% - получаете при накоплении 4000 бонусных рублей<br/>
                </p>
                До встречи!
            </div>
            <section className="main__form">
                <div className="main__form__background" />
                <form className="form" onSubmit={ onSubmit }>
                    <h1 className="form__title">Проверка участия в программе</h1>
                    <div ref={ beforeUserCheckRef } className="form__inputs form__inputs--center">
                        <div className="form__input">
                            <label>Телефон</label>
                            <InputMask mask="+7 (999) 999-99-99" name="phone" value={ phone } onChange={ ({ target: { value}}) => setPhone(value) } />
                        </div>

                        <div className="form__input form__inputs--hidden" ref={ smsInputBlockRef }>
                            <label>СМС-код</label>
                            <input type="text" name="code" autoComplete="one-time-code" value={ code } onChange={ ({ target: { value}}) => setCode(value) } ref={ codeRef } disabled />
                        </div>

                        <div className="form__input--button" ref={ smsBlockRef }>
                            <p className="form__timer form__timer--hidden" ref={ timerRef }>{ parseTime(time) }</p>
                            <button type="button" onClick={ onSendCodeClick } className="form__button" ref={ codeBtnRef }>Выслать код</button>
                        </div>
                    </div>
                    <div ref={ afterUserCheckRef } className="form__inputs form__inputs--hidden">

                        <div className="form__input">
                            <label>Имя</label>
                            <input type="text" name="firstName" value={ firstName } onChange={ ({ target: { value}}) => setFirstName(value) } />
                        </div>

                        <div className="form__input">
                            <label>Фамилия</label>
                            <input type="text" name="lastName" value={ lastName } onChange={ ({ target: { value}}) => setLastName(value) } />
                        </div>

                        <div className="form__input">
                            <label>Дата рождения</label>
                            <InputMask mask="99.99.9999" name="dob" value={ dob } onChange={ ({ target: { value}}) => setDob(value) } />
                        </div>

                        <div className="form__input">
                            <label>E-mail</label>
                            <input type="email" name="email" value={ email } onChange={ ({ target: { value}}) => setEmail(value) } />
                        </div>

                    </div>

                    <div ref={ afterUserCheckRadiosRef } className="form__input--radios form__inputs--hidden">
                        <div className="form__radio">
                            <input type="radio" id="form-sex-female" name="sex" value="female" onChange={ ({ target: { value}}) => setSex(value) } defaultChecked />
                            <label htmlFor="form-sex-female">Женщина</label>
                        </div>

                        <div className="form__radio">
                            <input type="radio" id="form-sex-male" name="sex" value="male" onChange={ ({ target: { value}}) => setSex(value) } />
                            <label htmlFor="form-sex-male">Мужчина</label>
                        </div>
                    </div>

                    {
                        signupIsEnabled ? (
                            <Fragment>
                                <div className="form__disclaimer">
                                    <div className="form__checkbox">
                                        <input type="checkbox" id="disclaimer" name="disclaimer" defaultChecked onChange={ () => setDisclaimer(!disclaimer) } />
                                        <label htmlFor="disclaimer">Я соглашаюсь с <a href={ PrivacyPolicy } target="_blank" rel="noopener noreferrer">Политикой конфиденциальности</a></label>
                                    </div>
                                </div>

                                <button
                                    type="submit"
                                    className="form__submit"
                                    ref={ submitRef }
                                    disabled={ !disclaimer || code.length !== 4 }>
                                    <span>Зарегистрироваться</span>
                                </button>
                            </Fragment>
                        ) : null
                    }

                    <p className="form__notification" ref={ notificationRef } />

                    <div className="form__after-signup" ref={ afterSignup }>
                        {/*<h3 className="form__card-number">Ваш номер бонусной карты: <strong>{ card }</strong></h3>*/}
                        <a href={ appleWallet } target="_blank" rel="noreferrer nofollow noopener">
                            <img src={ AppleWalletLogo } alt="Добавить в Apple Wallet" style={{ height: "3rem", width: "auto" }} />
                        </a>
                        <a href={ `https://pay.google.com/gp/v/save/${googleWallet}` } target="_blank" rel="noreferrer nofollow noopener">
                            <img src={ GoogleWalletLogo } alt="Добавить в Google Wallet" style={{ height: "3rem", width: "auto" }} />
                        </a>
                    </div>
                </form>
            </section>
        </main>
    )
}

export default App